@font-face {
    font-family: 'ZillaSlab';
    src: url("./ZillaSlab-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'Graphik';
    src: url("./GraphikCondensed-Bold.otf") format("opentype");
}